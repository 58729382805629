import React from 'react';
import Layout from '../components/layout';

import Divider from '../components/Divider';
import Seo from '../components/seo';
import Blob from "../images/blobs/orange.svg"
import appleBadge from '../images/apple_store_badge.svg';
import googleBadge from '../images/google-play-badge.svg'
import Screenshot from '../images/cahoot-login-screen.png'

export default function Mobile() {
    return (
        <Layout>
            <Seo title="Cahoot - Mobile" />
            <div id="about" className="relative">
                <div className="md:overflow-hidden md:pt-12 lg:relative lg:py-18 flex content-center">
                    <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl ">
                        <h1 className="text-3xl lg:text-4xl font-extrabold text-cahoot-blue-med leading-5 tracking-tight sm:text-4xl font-header text-center">
                            Cahoot Mobile
                        </h1>
                    </div>
                </div>
                <div className="-mt-12 md:-mt-24 lg:-mt-36">
                    <Divider color="mediumGray1" />
                </div>
                {/* CTA */}
                <div className="relative bg-cahoot-gray-med text-center md:text-left">
                    <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:pb-20 md:grid md:grid-cols-2 lg:gap-24 md:place-items-end lg:place-items-stretch">
                        <div className="relative sm:py-16 md:py-0">
                            <div
                                aria-hidden="true"
                                className="hidden lg:block lg:inset-y-0 lg:right-0 lg:w-screen"
                            >
                                <img src={Blob} alt="Decorative blob" className="absolute h-[26rem] inset-y-0 w-full" />

                            </div>
                            <div className="hidden md:block lg:hidden pt-12 pl-6">
                                <h2 className="text-3xl font-header text-cahoot-blue-dark font-extrabold tracking-tight">
                                    Download our App
                                </h2>
                                <div className="mt-6 text-cahoot-blue-dark font-text space-y-6 leading-normal">
                                    <p className="lg:text-lg">
                                        If your organization uses the <span className="font-header">Cahoot</span> platform, download our mobile application to stay up-to-date. From events to news and push notifications you will stay informed whenever you want, wherever you want.
                                    </p>

                                </div>
                            </div>
                            {/* Small CTA */}
                            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 md:hidden text-cahoot-blue-dark">
                                <div className="pt-12 sm:pt-16">
                                    <h2 className="text-2xl font-header font-extrabold tracking-tight sm:text-4xl">
                                        Download our App
                                    </h2>
                                    <div className="mt-6 space-y-6 font-text">
                                        <p className="text-lg">
                                            If your organization uses the <span className="font-header">Cahoot</span> platform, download our mobile application to stay up-to-date. From events to news and push notifications you will stay informed whenever you want, wherever you want.
                                        </p>
                                        <h4 className='font-header pt-4'>Choose your store</h4>
                                        <div className="flex flex-row justify-center pb-6">
                                            <div className="flex">
                                                {/* Store icons*/}
                                                {/* <div className='grid grid-rows'> */}
                                                <div className='pr-6'>
                                                    <a target="_blank" rel="noreferrer" href="https://apps.apple.com/us/app/cahoot-stay-connected/id1612364619"><img alt="Apple store badge" src={appleBadge} className='w-32' /></a>
                                                </div>
                                                <div>
                                                    <a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.apotheosistech.cahoot"><img alt="Google Playstore badge" src={googleBadge} className='w-36' /></a>
                                                </div>
                                                {/* </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="hidden lg:block relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:p-12">
                                <div className="relative h-48">
                                    <div className="absolute right-48">
                                        <img src={Screenshot} alt="Case study screenshot" className='object-contain h-72 rounded-2xl shadow-xl -rotate-6' />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Medium CTA */}
                        <div className="hidden md:block lg:hidden relative mx-auto max-w-md px-4 lg:px-0">
                            {/* <div className="pt-12">
                                    <h2 className="text-3xl font-header text-cahoot-blue-dark font-extrabold tracking-tight">
                                        Download our App
                                    </h2>
                                    <div className="mt-6 text-cahoot-blue-dark font-text space-y-6 leading-normal">
                                        <p className="lg:text-lg">
                                            If your organization uses the <span className="font-header">Cahoot</span> platform, download our mobile application to stay up-to-date. From events to news and push notifications you will stay informed whenever you want, wherever you want.
                                        </p>

                                    </div>
                                </div> */}
                            <div>
                                {/* Store icons*/}
                                <div className="flex flex-row justify-center">

                                    <div className="flex my-4">
                                        {/* Store icons*/}
                                        <div className='grid grid-rows'>
                                            <div>
                                                <a target="_blank" rel="noreferrer" href="https://apps.apple.com/us/app/cahoot-stay-connected/id1612364619"><img alt="Apple store badge" src={appleBadge} className='w-32' /></a>
                                            </div>
                                            <div>
                                                <a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.apotheosistech.cahoot"><img alt="Google Playstore badge" src={googleBadge} className='w-32' /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Large CTA */}
                        <div className="hidden lg:block relative mx-auto max-w-md px-4 lg:px-0">
                            <div className="pt-12">
                                <h2 className="text-3xl font-header text-cahoot-blue-dark font-extrabold tracking-tight">
                                    Download our App
                                </h2>
                                <div className="mt-6 text-cahoot-blue-dark font-text space-y-6 leading-normal">
                                    <p className="lg:text-lg">
                                        If your organization uses the <span className="font-header">Cahoot</span> platform, download our mobile application to stay up-to-date. From events to news and push notifications you will stay informed whenever you want, wherever you want.
                                    </p>
                                    <div className="flex flex-row">
                                        <div className="flex mt-4">
                                            {/* Store icons*/}
                                            <div className='grid grid-cols-2 gap-4 content-center'>
                                                <div>
                                                    <a target="_blank" rel="noreferrer" href="https://apps.apple.com/us/app/cahoot-stay-connected/id1612364619"><img alt="Apple store badge" src={appleBadge} className='h-10' /></a>
                                                </div>
                                                <div>
                                                    <a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.apotheosistech.cahoot"><img alt="Google Playstore badge" src={googleBadge} className='h-10' /></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Content start */}
                <div className="bg-cahoot-white -mt-10 pt-20 pb-20 lg:pb-32 px-6">
                    <div className="lg:mx-auto lg:max-w-3xl font-text">
                        <div className='text-xl font-header text-cahoot-blue-med lg:leading-9 mt-10'>Be in step with those that matter</div>
                        <p className="mt-5">
                            With Cahoot, you can be in cahoots with your group and keep track of updates that matter. The Cahoot mobile app is designed to become the go-to destination for event updates, news and articles from your organization. The app tracks information released by the groups you're interested in and provides a stream of updates that brings you up to speed. With this information handy, you can make plans with ease and not miss out on things that are important to you.
                        </p>
                        <div className='text-xl font-header text-cahoot-blue-med lg:leading-9 mt-10'>Why you should download Cahoot today</div>
                        <p className="mt-5">
                            Our feature-rich Cahoot mobile app will empower you to:
                            <ul className="mt-5">
                                <li>Stay current with your organization's news, events and announcements.</li>
                                <li>Follow the groups of your choice and easily view consolidated information.</li>
                                <li>Receive important group notifications so you're not left behind.</li>
                            </ul>
                        </p>
                        <div className='text-xl font-header text-cahoot-blue-med lg:leading-9 mt-10'>A digital experience platform to sustain your organization’s growing needs</div>
                        <div className="mt-5">
                            Are you the manager or supervisor of your organization?
                        </div>
                        <div className="mt-5">
                            The Cahoot platform is designed to give you all the tools you need to effortlessly create and share content that is important to your group. Our enterprise-grade software performs the function of multiple apps while reducing your dependency on many tools.
                        </div>
                        <div className="mt-5">
                            Our easy-to-use platform helps you quickly create, schedule and share updates with your preferred audience. Reduce conflict, streamline communication and forge lasting partnerships today with our Cahoot Digital Experience Platform. <a href="/contact" alt="Contact Us" className='text-cahoot-blue-med hover:text-cahoot-orange'>Contact us</a> for more information!
                        </div>
                    </div>
                </div>

            </div>
        </Layout>
    )
}